var wizard = function () {
    var wizard = this;

    this.step = undefined;
    this.idItem = undefined;

    this.init = function (idItem, step, hideBibliographyStep, hideItineraryStep, hideTagStep) {

        wizard.step = step;
        wizard.idItem = idItem;
        wizard.hideBibliographyStep = hideBibliographyStep;
        wizard.hideItineraryStep = hideItineraryStep;
        wizard.hideTagStep = hideTagStep;

        wizard.bindEvents();

        wizard.getContent();

        wizard.toggleClose(wizard.step);
        wizard.hideBibliography(wizard.hideBibliographyStep);
        wizard.hideItinerary(wizard.hideItineraryStep);
        wizard.hideTag(wizard.hideTagStep);
    };

    this.bindEvents = function () {
        $('[data-interaction=next]').unbind('click').bind('click', function () {
            wizard.next();
        });

        $('[data-interaction=back]').unbind('click').bind('click', function () {
            wizard.back();
        });

        $('[data-interaction=save_all]').unbind('click').bind('click', function () {
            wizard.save_all();
        });

        $('[data-interaction=delete_all]').unbind('click').bind('click', function () {
            wizard.delete_all();
        });

        $('[data-interaction=close]').unbind('click').bind('click', function () {
            wizard.close();
        });

        $('[data-interaction=step]').unbind('click').bind('click', function (e) {
            e.preventDefault();
            e.stopPropagation();

            var before = $("#wizard").data("before_change_step");
            if (typeof before === 'function') {
                // se è una funzione -> prima di eseguire metto il bind su un evento -> eseguo la funzione e rimetto a null il data
                // else faccio quello che ho sempre fatto
                var self = this;
                $("#wizard").off("before_change_success").on("before_change_success", function () {
                    wizard.step = $(self).attr('data-step');

                    wizard.toggleClose(wizard.step);
                    wizard.getContent();
                });

                before();

                $("#wizard").data("before_change_step", null);
            } else {
                wizard.step = $(this).attr('data-step');

                wizard.toggleClose(wizard.step);
                wizard.getContent();
            }

            return false;
        });
    };

    this.hideItinerary = function(hideItinerary) {
        if(hideItinerary == true) {
            $('[data-step=8]').children('.step').css("border-color", "grey");
            $('[data-step=9]').children('.step').css("border-color", "grey");

            $('[data-step=8]').children('.step').html("<i class='fa fa-lock' style='color: orange'></i>");
            $('[data-step=9]').children('.step').html("<i class='fa fa-lock' style='color: orange'></i>");
        }
    };

    this.hideTag = function(hideTag) {
        if(hideTag == true) {
            $('[data-step=7]').children('.step').css("border-color", "grey");

            $('[data-step=7]').children('.step').html("<i class='fa fa-lock' style='color: orange'></i>");
        }
    };

    this.hideBibliography = function(hideBibliographyStep) {
        if(hideBibliographyStep == true) {
            $('[data-step=4]').children('.step').css("border-color", "grey");

            $('[data-step=4]').children('.step').html("<i class='fa fa-lock' style='color: orange'></i>");
        }
    };

    this.toggleClose = function (step) {

        $('[data-show-on-step]').show();
        $('[data-no-show-on-step]').show();

        $('[data-show-on-step='+step+']').hide();
        $('[data-no-show-on-step='+step+']').hide();
    };

    this.next = function () {
        var func = $("#wizard").data("next");

        if (typeof func === 'function') {
            func(wizard.idItem, wizard.step);
        } else {
            $("#wizard").trigger('complete', ['next']);
        }
    };

    this.back = function () {
        var func = $("#wizard").data("back");

        if (typeof func === 'function') {
            func(wizard.idItem, wizard.step);
        } else {
            $("#wizard").trigger('complete', ['back']);
        }
    };

    this.save_all = function () {
        var func = $("#wizard").data("save_all");

        if (typeof func === 'function') {
            func(wizard.idItem, wizard.step);
        } else {
            window.location.href = app.baseUrl + "/" + $(this).attr('data-type');
            // app.href(app.baseUrl + '/item?idItem=' + wizard.idItem);
        }
    };

    this.delete_all = function () {
        if (confirm("Sei sicuro di voler cancellare tutto l\'item?")) {
            $.delete(app.baseUrl + '/item/' + wizard.idItem);
            app.href(app.baseUrl + '/item');
        }
    };

    this.close = function () {
        if(wizard.step == 2) {
            alert("Completa prima lo step 2 con tutti i dati obbligatori e premi avanti per concludere l'inserimento");
            return;
        }

        app.block(1);
        $.post(app.baseUrl + "/item/" + wizard.idItem + "/close")
            .done(function (data) {
                app.block(0);

                if (data.response) {
                    window.location.href = app.baseUrl + "/" + data.type;
                    // app.href(app.baseUrl + '/item?idItem=' + wizard.idItem);
                } else {

                    var arrayLength = data.step.length;
                    for (var i = 0; i < arrayLength; i++) {
                        var data_step = $('[data-step='+data.step[i]+']');
                        data_step.children('.step').css("border-color", "#D15B47");
                    }

                    app.warning(data.message);
                }
            })
            .fail(function (data) {
                app.block(0);
            });
    };

    this.getContent = function () {
        // recupero l'array per recuperare il contenuto e modificare
        app.block(1);
        $.get(app.baseUrl + '/item/' + wizard.idItem + '/wizard/step/' + wizard.step)
            .done(function (data) {
                // aggiorno lo step corrente
                wizard.step = data.step;

                wizard.toggleClose(wizard.step);

                // aggiorno lo stato del wizard
                wizard.updateStatus();

                $.get(data.url)
                    .done(function (data) {
                        $("#content").html(data);

                        app.runBind();

                        $("#wizard").unbind("complete").bind("complete", function (event, direction) {
                            // aggiorno lo step corrente
                            if (direction === 'next')
                                wizard.step++;
                            else
                                wizard.step--;

                            $("#wizard").data('next', null);
                            $("#wizard").data('back', null);
                            $("#wizard").data('save_all', null);
                            $("#wizard").data('delete_all', null);

                            wizard.getContent();
                        });

                        app.block(0);
                    })
                    .fail(function (data) {
                        app.block(0);
                    });
            })
            .fail(function (data) {
                app.block(0);
            });
    };

    this.updateStatus = function () {
        $("#wizard").find(".steps").find('li').each(function () {
            if ($(this).attr('data-step') <= wizard.step)
                $(this).addClass("active");
            else
                $(this).removeClass("active");
        });
    };

};

wizard.bindFormEvents = function () {
    $('[data-interaction=create-new]').unbind('click').bind('click', function (e) {
        e.preventDefault();
        e.stopPropagation();

        var url = $(this).attr('data-url');
        var target = $(this).attr('data-target');

        $.get(url)
            .done(function (data) {
                $("#wizardModal").find(".modal-body").html(data);
                $("#wizardModal").data('value', null);

                $("#wizardModal").unbind("hidden.bs.modal").bind("hidden.bs.modal", function () {
                    var value = $("#wizardModal").data('value');

                    if (value) {
                        $(target).append(new Option(value.text, value.id, false, false)).val(value.id).trigger('change');
                    }
                });

                $("#wizardModal").modal("show");
            });

        return false;
    });
};
